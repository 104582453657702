<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Add Contact"
    >
      <template #subheader>
        <div>Select how you’d like to add your contacts</div>
      </template>
    </PageHeader>
    <v-row
      class="px-0 mb-4"
    >
      <v-col
        v-for="option in options"
        :key="option.header"
        class="pa-0"
        cols="12"
        sm="4"
      >
        <BoxTypeSelector
          :header="option.header"
          :subheader="option.subheader"
          :icon="option.icon"
          :value="option.value"
          @input="handleSelection"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import BoxTypeSelector from "@/sharedComponents/BoxTypeSelector";

export default {
  name: "AddContact",
  metaInfo: {
    title: 'Add Contact'
  },
  components: {
    BoxTypeSelector,
    PageHeader,
  },
  data() {
    return {
      options: [
        {
          icon: "mdi-account",
          header: "Single Contact",
          subheader: "Create a single contact by manually filling out their information",
          value: 'AddSingleContact',
        },
        {
          icon: "mdi-format-list-bulleted",
          header: "Bulk Add/Update",
          subheader: "Import a file to add or update multiple contacts",
          value: 'BulkImportContact',
        },
        {
          icon: "mdi-magnify",
          header: "Contact Match Tool",
          subheader: "Find matching contacts before uploading a new list to avoid duplicate info",
          value: 'ContactMatchTool',
        },
      ],
    };
  },
  created() {
    if (this.$attrs?.type) {
      this.handleSelection(this.$attrs.type);
    }
  },
  methods: {
    handleSelection(value) {
      this.$router.push({ name: value });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
