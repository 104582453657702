<template>
  <v-list
    subheader
    two-line
    :class="{
      'box-type-selector mx-3 pb-0 mb-3 mb-sm-0': true,
      'box-type-selector-selected': selected
    }"
  >
    <v-list-item link @click="$emit('input', value)">
      <v-list-item-avatar class="py-6">
        <v-icon class="box-icon">
          {{ icon }}
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="header" />

        <v-list-item-subtitle v-text="subheader" />
      </v-list-item-content>

      <v-list-item-action>
        <v-btn v-if="selected" icon>
          <v-icon>$check</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "BoxTypeSelector",
  props: {
    header: {
      default: "",
      type: String,
    },
    subheader: {
      default: "",
      type: String,
    },
    icon: {
      default: "mdi-home",
      type: String,
    },
    value: {
      default: null,
    },
    selected: {
      default: false,
      type: Boolean,
    }
  },
};
</script>

<style lang="scss" scoped>
.box-type-selector {
  border-radius: 3px !important;
  border: solid 1px #dceff9 !important;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 100%;

  &-selected {
    border: solid 2px #2b84eb !important;
    background-color: rgba(43, 132, 235, 0.05) !important;
    margin-top: 0;
    margin-bottom: 0;
  }
  .v-list-item {
    height: 100%;
  }
  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  .v-list-item__subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #66788e;
    word-wrap: break-word;
    white-space: normal;
  }

  .box-icon {
    color: #2b84eb;
    background-color: #dceff9;
  }
}
</style>
